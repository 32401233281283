(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.default = function (height, speed) {

  // set up scroll to top
  $(window).scroll(function () {
    if ($(this).scrollTop() > height) {
      $('[data-scrolltop]').removeClass('fadeOut');
      $('[data-scrolltop]').addClass('fadeIn');
    } else {

      $('[data-scrolltop]').removeClass('fadeIn');
      $('[data-scrolltop]').addClass('fadeOut');
    }
  });

  $('[data-scrolltop]').click(function () {
    var body = document.querySelector('body');
    smoothScroll.animateScroll(body);
  });
};

; /* global jQuery, SmoothScroll */

// Function:	initScrollTop
// Purpose:		Initialize scroll to top element using [data-scrolltop] attribute
// Params:		Height, Speed in Milliseconds (optional)

/* global $ */

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

var url = window.location.href;
// Homepage Map is first in conditional below
if (window.location.pathname == '/') {
  window.initMap = function () {

    // Specify features and elements to define styles.
    var styleArray = [{
      "featureType": "administrative",
      "elementType": "labels.text.fill",
      "stylers": [{
        "color": "#002855"
      }]
    }, {
      "featureType": "administrative",
      "elementType": "labels.text.stroke",
      "stylers": [{
        "color": "#ffffff"
      }, {
        "visibility": "on"
      }]
    }, {
      "featureType": "administrative.province",
      "elementType": "geometry.stroke",
      "stylers": [{
        "color": "#968c83"
      }, {
        "lightness": "40"
      }, {
        "visibility": "on"
      }, {
        "weight": "2.00"
      }]
    }, {
      "featureType": "administrative.province",
      "elementType": "labels",
      "stylers": [{
        "color": "#968c83"
      }, {
        "visibility": "simplified"
      }]
    }, {
      "featureType": "landscape",
      "stylers": [{
        "color": "#EDEEEF"
      }, {
        "lightness": "30"
      }]
    }, {
      "featureType": "landscape.natural.landcover",
      "stylers": [{
        "lightness": "0"
      }]
    }, {
      "featureType": "poi",
      "stylers": [{
        "visibility": "off"
      }]
    }, {
      "featureType": "road",
      "stylers": [{
        "saturation": -100
      }, {
        "lightness": 70
      }, {
        "visibility": "simplified"
      }]
    }, {
      "featureType": "road.arterial",
      "elementType": "labels.icon",
      "stylers": [{
        "visibility": "off"
      }]
    }, {
      "featureType": "road.highway",
      "stylers": [{
        "visibility": "on"
      }]
    }, {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [{
        "color": "#556877"
      }, {
        "lightness": "40"
      }, {
        "visibility": "on"
      }]
    }, {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [{
        "color": "#a2aaad"
      }, {
        "lightness": "-32"
      }, {
        "visibility": "off"
      }]
    }, {
      "featureType": "road.highway",
      "elementType": "labels.icon",
      "stylers": [{
        "hue": "#162a52"
      }, {
        "saturation": "90"
      }, {
        "lightness": "0"
      }, {
        "visibility": "simplified"
      }]
    }, {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [{
        "color": "#747a98"
      }]
    }, {
      "featureType": "water",
      "stylers": [{
        "color": "#9bd3dd"
      }, {
        "saturation": "0"
      }, {
        "lightness": "50"
      }, {
        "gamma": "1.00"
      }, {
        "visibility": "on"
      }]
    }, {
      "featureType": "water",
      "elementType": "labels",
      "stylers": [{
        "visibility": "off"
      }]
    }];

    // Create a map object and specify the DOM element for display.

    var lat = $('#map').attr('data-lat'),
        lng = $('#map').attr('data-lng');

    var locations = [['<a href="/ear-nose-throat/">Ear, Nose and Throat</a>, <a href="/infectious-diseases/">Infectious Diseases</a>, <a href="/nephrology-uhc/">Nephrology</a>, <a href="/plastic-surgery/">Plastic Surgery</a>', 39.3287, -80.2417677], ['<a href="/ophthalmology/">Ophthalmology</a>', 39.280201, -80.344602], ['<a href="/pulmonary-critical-care-medicine/">Pulmonary & Critical Care Medicine</a>, <a href="/thoracic-surgery/">Thoracic Surgery</a>', 39.300498, -80.277809]];
    var map = new google.maps.Map(document.getElementById('map'), {
      center: {
        lat: 39.301918,
        lng: -80.289737
      },
      scrollwheel: false,
      // Apply the map style array to the map.
      styles: styleArray,
      zoom: 12
    });

    var infowindow = new google.maps.InfoWindow();

    var marker, i;

    var svgIcon = {
      url: '/wp-content/uploads/2018/11/pin.svg',
      scaledSize: new google.maps.Size(71.58, 120)
    };

    for (i = 0; i < locations.length; i++) {
      marker = new google.maps.Marker({
        position: new google.maps.LatLng(locations[i][1], locations[i][2]),
        map: map,
        animation: google.maps.Animation.DROP,
        optimized: false,
        icon: svgIcon
      });

      google.maps.event.addListener(marker, 'click', function (marker, i) {
        return function () {
          infowindow.setContent(locations[i][0]);
          infowindow.open(map, marker);
        };
      }(marker, i));
    }
  };

  var App = function () {
    function App() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      _classCallCheck(this, App);

      this.smoothScroll = new SmoothScroll();
      this.init();
    }

    _createClass(App, [{
      key: "init",
      value: function init() {
        // initialize medium screen+ components

        // google map on the homepage - conditional to load the normal info window or the custom one we made for desktops
        var mapEl = $('#map');

        // initialize maps
        if (mapEl.length >= 1) {
          var gMapsLoaded = false;

          window.gMapsCallback = function () {
            gMapsLoaded = true;
            $(window).trigger('gMapsLoaded');
          };

          window.loadGoogleMaps = function () {
            if (gMapsLoaded) return window.gMapsCallback();
            var script_tag = document.createElement('script');
            script_tag.setAttribute("type", "text/javascript");
            script_tag.setAttribute("src", "https://maps.google.com/maps/api/js?key=AIzaSyDGmp6rqp0l_JvDMeA1zllaL4GykbZqYmU&sensor=false&callback=gMapsCallback");
            (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script_tag);
          };

          // script load event to initMap callback
          // $(window).bind('gMapsLoaded', initMap);

          window.loadGoogleMaps();
        } // Ends initializing maps

        // sticky mobile menu button
        $(window).scroll(function () {
          var scroll = $(window).scrollTop();

          if (scroll >= 80) {
            $(".usa-menu-btn").addClass("fixedmobile");
          } else {
            $(".usa-menu-btn").removeClass("fixedmobile");
          }
        });
      }
    }]);

    return App;
  }();
} else {
  // anything NOT homepage
  window.initMap = function () {

    // Specify features and elements to define styles.
    var styleArray = [{
      "featureType": "administrative",
      "elementType": "labels.text.fill",
      "stylers": [{
        "color": "#002855"
      }]
    }, {
      "featureType": "administrative",
      "elementType": "labels.text.stroke",
      "stylers": [{
        "color": "#ffffff"
      }, {
        "visibility": "on"
      }]
    }, {
      "featureType": "administrative.province",
      "elementType": "geometry.stroke",
      "stylers": [{
        "color": "#968c83"
      }, {
        "lightness": "40"
      }, {
        "visibility": "on"
      }, {
        "weight": "2.00"
      }]
    }, {
      "featureType": "administrative.province",
      "elementType": "labels",
      "stylers": [{
        "color": "#968c83"
      }, {
        "visibility": "simplified"
      }]
    }, {
      "featureType": "landscape",
      "stylers": [{
        "color": "#EDEEEF"
      }, {
        "lightness": "30"
      }]
    }, {
      "featureType": "landscape.natural.landcover",
      "stylers": [{
        "lightness": "0"
      }]
    }, {
      "featureType": "poi",
      "stylers": [{
        "visibility": "off"
      }]
    }, {
      "featureType": "road",
      "stylers": [{
        "saturation": -100
      }, {
        "lightness": 45
      }, {
        "visibility": "simplified"
      }]
    }, {
      "featureType": "road.arterial",
      "elementType": "labels.icon",
      "stylers": [{
        "visibility": "off"
      }]
    }, {
      "featureType": "road.highway",
      "stylers": [{
        "visibility": "on"
      }]
    }, {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [{
        "color": "#333f48"
      }, {
        "lightness": "60"
      }, {
        "visibility": "on"
      }]
    }, {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [{
        "color": "#a2aaad"
      }, {
        "lightness": "-32"
      }, {
        "visibility": "off"
      }]
    }, {
      "featureType": "road.highway",
      "elementType": "labels.icon",
      "stylers": [{
        "hue": "#00dcff"
      }, {
        "saturation": "33"
      }, {
        "lightness": "-9"
      }, {
        "visibility": "simplified"
      }]
    }, {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [{
        "color": "#747a98"
      }]
    }, {
      "featureType": "water",
      "stylers": [{
        "color": "#9bd3dd"
      }, {
        "saturation": "0"
      }, {
        "lightness": "100"
      }, {
        "gamma": "1.00"
      }, {
        "visibility": "on"
      }]
    }, {
      "featureType": "water",
      "elementType": "labels",
      "stylers": [{
        "visibility": "off"
      }]
    }];

    // Create a map object and specify the DOM element for display.

    var latlng = new google.maps.LatLng($('#mapInd').attr('data-lat'), $('#mapInd').attr('data-lng'));

    var map = new google.maps.Map(document.getElementById('mapInd'), {
      center: latlng,
      scrollwheel: false,
      // Apply the map style array to the map.
      styles: styleArray,
      zoom: 18
    });

    var svgIcon = {
      url: '/wp-content/uploads/2018/11/pin.svg',
      scaledSize: new google.maps.Size(71.58, 120)
    };

    var infowindow = new google.maps.InfoWindow();

    // create marker
    var marker = new google.maps.Marker({
      position: latlng,
      map: map,
      animation: google.maps.Animation.DROP,
      optimized: false,
      icon: svgIcon
    });

    google.maps.event.addListener(marker, 'click', function (marker) {
      return function () {
        infowindow.setContent($('.infowindow-margin p strong').text());
        infowindow.open(map, marker);
      };
    }(marker));
  };
  var App = function () {
    function App() {
      var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      _classCallCheck(this, App);

      //this.smoothScroll = new SmoothScroll();
      this.init();
    }

    _createClass(App, [{
      key: "init",
      value: function init() {

        // initialize medium screen+ components

        // google map on the homepage - conditional to load the normal info window or the custom one we made for desktops
        var mapEl = $('#mapInd');

        // initialize maps
        if (mapEl.length >= 1) {
          var gMapsLoaded = false;

          window.gMapsCallback = function () {
            gMapsLoaded = true;
            $(window).trigger('gMapsLoaded');
          };

          window.loadGoogleMaps = function () {
            if (gMapsLoaded) return window.gMapsCallback();
            var script_tag = document.createElement('script');
            script_tag.setAttribute("type", "text/javascript");
            script_tag.setAttribute("src", "https://maps.google.com/maps/api/js?key=AIzaSyDSRmaFgtiIS19pu_PCEfULr4c_wx55w0o&sensor=false&callback=gMapsCallback");
            (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script_tag);
          };

          // script load event to initMap callback
          // $(window).bind('gMapsLoaded', initMap);

          window.loadGoogleMaps();
        } // Ends initializing maps

        // sticky mobile menu button
        $(window).scroll(function () {
          var scroll = $(window).scrollTop();

          if (scroll >= 80) {
            $(".usa-menu-btn").addClass("fixedmobile");
          } else {
            $(".usa-menu-btn").removeClass("fixedmobile");
          }
        });
      }
    }]);

    return App;
  }();
}

window.onload = function () {
  window.$ = window.jQuery;
  // create new App object
  var $bs = new App();
  window.$bs = $bs;
};

},{}]},{},[1]);
